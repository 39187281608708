import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';

export interface TimeSlotAvailabilityStatus {
  allSlotsAreFull: boolean;
  tooLateToBookAllSlots: boolean;
  tooEarlyToBookAllSlots: boolean;
}

export const getTimeSlotsAvailabilityStatuses = (
  slotAvailabilities: QueryAvailabilityResponse,
): Map<string, TimeSlotAvailabilityStatus> => {
  const timeSlotsAvailabilityStatus = new Map<
    string,
    TimeSlotAvailabilityStatus
  >();

  const onlyFutureSlotAvailabilities: SlotAvailability[] = getOnlyFutureSlotAvailabilities(
    slotAvailabilities,
  );
  const compressedSlotsByStartTimestamp: Map<
    string,
    SlotAvailability[]
  > = compressSlotsByStartTimestamp(onlyFutureSlotAvailabilities);
  compressedSlotsByStartTimestamp.forEach(
    (slots: SlotAvailability[], startTimestamp: string) => {
      const timeSlotStatus = getTimeSlotAvailabilityStatus(slots);
      timeSlotsAvailabilityStatus.set(startTimestamp, timeSlotStatus);
    },
  );

  return timeSlotsAvailabilityStatus;
};

const getOnlyFutureSlotAvailabilities = (
  availableSlots?: QueryAvailabilityResponse,
): SlotAvailability[] => {
  const now = new Date();
  const onlyFutureEntries = availableSlots?.availabilityEntries?.filter(
    (availabilityEntry) => {
      const slotStartTime = availabilityEntry?.slot?.start;
      return slotStartTime && new Date(slotStartTime) >= now;
    },
  );
  return onlyFutureEntries || [];
};

const compressSlotsByStartTimestamp = (
  slotAvailabilities: SlotAvailability[],
): Map<string, SlotAvailability[]> => {
  const slotsByStartTimestamp = new Map<string, SlotAvailability[]>();
  slotAvailabilities?.forEach((slot: SlotAvailability) => {
    const startTimeStamp = slot?.slot?.start;
    if (startTimeStamp) {
      const currentValue = slotsByStartTimestamp.get(startTimeStamp) || [];
      slotsByStartTimestamp.set(startTimeStamp, [...currentValue, slot]);
    }
  });
  return slotsByStartTimestamp;
};

const getTimeSlotAvailabilityStatus = (
  slots: SlotAvailability[],
): TimeSlotAvailabilityStatus => {
  const allSlotsAreFull = isAllSlotsAreFull(slots);
  const tooLateToBookAllSlots = isTooLateToBookAllSlots(slots);
  const tooEarlyToBookAllSlots = isTooEarlyToBookAllSlots(slots);

  return {
    allSlotsAreFull,
    tooLateToBookAllSlots,
    tooEarlyToBookAllSlots,
  };
};

const isAllSlotsAreFull = (slots: SlotAvailability[]) => {
  return slots.every((slotAvailability: SlotAvailability) =>
    isFullSlot(slotAvailability),
  );
};

const isTooLateToBookAllSlots = (slots: SlotAvailability[]) => {
  return slots.every((slotAvailability: SlotAvailability) =>
    isTooLateToBookSlot(slotAvailability),
  );
};

const isTooEarlyToBookAllSlots = (slots: SlotAvailability[]) => {
  return slots.every((slotAvailability: SlotAvailability) =>
    isTooEarlyToBookSlot(slotAvailability),
  );
};

const isFullSlot = (slot: SlotAvailability) => {
  return slot.spotsOpen && slot.spotsTotal && slot.spotsOpen >= slot.spotsTotal;
};

const isTooEarlyToBookSlot = (slot: SlotAvailability) => {
  return slot.bookingPolicyViolations?.isTooEarlyToBook;
};

const isTooLateToBookSlot = (slot: SlotAvailability) => {
  return slot.bookingPolicyViolations?.isTooLateToBook;
};
