export const getLocalTimezone = () => {
  return new Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getFormattedDateString = (date: Date) => {
  const addZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);
  return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(
    date.getDate(),
  )}T00:00:00`;
};

export const getLocalDateFromDateString = (dateString: string) => {
  return new Date(dateString);
};

export const formatSelectedDateToViewFormat = (
  selectedDate: string,
  dateRegionalSettingsLocale?: string,
) => {
  const date = new Date(selectedDate);
  const formatOptions = {
    day: 'numeric',
    weekday: 'long',
    month: 'long',
  };
  return formatter(date, formatOptions, dateRegionalSettingsLocale);
};

export const formatTimeToViewFormat = (timeStamp: string, locale?: string) => {
  const time = new Date(timeStamp);
  const formatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return formatter(time, formatOptions, locale).toLowerCase();
};

export const formatRFCtoDateAndTime = (
  rfcDate: string,
  dateRegionalSettingsLocale: string,
) => {
  const formattedDate = formatDate({
    rfcDate,
    dateRegionalSettingsLocale,
  });
  const formattedTime = formatTime({
    rfcDate,
    dateRegionalSettingsLocale,
  });
  return `${formattedDate}, ${formattedTime}`;
};

const formatDate = ({
  rfcDate,
  dateRegionalSettingsLocale,
}: {
  rfcDate: string;
  dateRegionalSettingsLocale?: string;
}) => {
  const date = new Date(rfcDate);
  const formatOptions = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  };
  return formatter(date, formatOptions, dateRegionalSettingsLocale);
};

const formatTime = ({
  rfcDate,
  dateRegionalSettingsLocale,
}: {
  rfcDate: string;
  dateRegionalSettingsLocale?: string;
}) => {
  const date = new Date(rfcDate);
  const formatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return formatter(date, formatOptions, dateRegionalSettingsLocale);
};

const formatter = (
  date: Date,
  formatOptions: Intl.DateTimeFormatOptions,
  dateRegionalSettingsLocale?: string,
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    dateRegionalSettingsLocale,
    formatOptions,
  );
  return dateTimeFormat.format(date);
};

export const minutesDifferencesBetweenTwoDates = (
  startDate: string,
  endDate: string,
) => {
  const secondsInDay = 86400;
  const minutesInHour = 60;
  const hoursInDay = 24;
  const delta =
    Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) /
    1000;

  const days = Math.floor(delta / secondsInDay);
  const hours =
    Math.floor(delta / (minutesInHour * minutesInHour)) % hoursInDay;
  const minutes = Math.floor(delta / minutesInHour) % minutesInHour;

  return minutes + hours * minutesInHour + days * hoursInDay;
};
