import {
  CalendarBiLogger,
  createCalendarBiLogger,
} from '../bi/biLoggerFactory';
import { BusinessInfo, BusinessInfoBase } from '@wix/bookings-uou-types';
import {
  CalendarState,
  TFunction,
} from '../../components/BookingCalendar/controller';

import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { CalendarApi } from '../../api/calendarApi';

export type CalendarContext = {
  wixSdkAdapter: WixOOISDKAdapter;
  biLogger: CalendarBiLogger;
  businessInfo: BusinessInfoBase;
  calendarApi: CalendarApi;
  settings: {
    get: Function;
  };
  t: TFunction;
};

export function createCalendarContext(
  flowAPI: any,
  businessInfo: BusinessInfo,
  calendarApi: CalendarApi,
  wixSdkAdapter: WixOOISDKAdapter,
  initialState: CalendarState,
): CalendarContext {
  const {
    settings,
    translations: { t },
  } = flowAPI;
  const biLogger = createCalendarBiLogger(
    flowAPI,
    initialState,
    wixSdkAdapter,
    businessInfo,
    settings,
  );

  return {
    wixSdkAdapter,
    biLogger,
    businessInfo,
    calendarApi,
    settings,
    t,
  };
}
