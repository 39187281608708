import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import settingsParams from '../../settingsParams';
import { CalendarContext } from '../../../../utils/context/contextFactory';

export type HeaderViewModel = {
  title: string;
  subtitle: string;
  isSubtitleVisible: boolean;
};

export function createHeaderViewModel({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): HeaderViewModel {
  const { settings } = context;
  const { selectedService } = state;
  const title = selectedService.info.name;
  const isSubtitleVisible = settings.get(
    settingsParams.headerSubtitleVisibility,
  );
  const subtitle = settings.get(
    settingsParams.headerSubtitleShouldBeServiceTagline,
  )
    ? selectedService.info.tagline
    : settings.get(settingsParams.headerSubtitleText);

  return {
    title,
    subtitle,
    isSubtitleVisible,
  };
}
