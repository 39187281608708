import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getFormattedDateString } from '../../../../utils/dateAndTime/dateAndTime';

export enum DateAvailabilityStatus {
  HAS_AVAILABLE_SLOTS = 'has_available_slots',
  HAS_UNAVAILABLE_SLOTS = 'has_slots',
}

export type DatePickerViewModel = {
  selectedDate: string;
  dateAvailabilityStatuses: Map<string, DateAvailabilityStatus>;
};

export function createDatePickerViewModel({
  state,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): DatePickerViewModel {
  const { selectedDate, dateAvailability } = state;
  const dateAvailabilityStatuses = new Map<string, DateAvailabilityStatus>();
  dateAvailability?.availabilityEntries?.forEach((dateAvailabilityResponse) => {
    dateAvailabilityStatuses.set(
      getFormattedDateString(dateAvailabilityResponse.date!),
      dateAvailabilityResponse.hasBookableSlots
        ? DateAvailabilityStatus.HAS_AVAILABLE_SLOTS
        : DateAvailabilityStatus.HAS_UNAVAILABLE_SLOTS,
    );
  });
  return { selectedDate: selectedDate!, dateAvailabilityStatuses };
}
