import { InitializeCalendarDateOptions, widgetDefaults } from './consts';
import { Logger as BookingsViewerBiLogger } from '@wix/bi-logger-wixboost-ugc';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { BusinessInfo } from '@wix/bookings-uou-types';
import settingsParams from '../../components/BookingCalendar/settingsParams';

export interface CalendarBiLogger extends BookingsViewerBiLogger {
  update: (state: CalendarState) => void;
}

export function createCalendarBiLogger(
  flowAPI: any,
  initialState: CalendarState,
  wixSdkAdapter: WixOOISDKAdapter,
  businessInfo: BusinessInfo,
  settings: any,
): CalendarBiLogger {
  const { bi: viewerBiLogger } = flowAPI;

  const getServiceLocationIds = (calendarState: CalendarState) =>
    calendarState?.selectedService?.locations
      ?.map((location) => location?.businessLocation?.id)
      .filter((locationId) => locationId !== undefined);
  const getServiceStaffIds = (calendarState: CalendarState) =>
    calendarState?.selectedService?.staffMembers?.map(
      (staffMember) => staffMember.id,
    );

  const mapStateToDefaultBiParams = (state: CalendarState) => {
    return {
      serviceType: state?.selectedService?.info?.type,
      serviceId: state?.selectedService?.id,
      selectedTimezone: state?.selectedTimezone,
      serviceProperties: JSON.stringify({
        locationIds: getServiceLocationIds(state),
        staffMemberIds: getServiceStaffIds(state),
        paymentOptions: state?.selectedService?.payment?.offeredAs,
        connectedSolutions: [],
        selectedFilters: [],
      }),
      selectedDate: state?.selectedDate,
      selectedMonth: state?.selectedMonth,
    };
  };

  const updateDefaultBiParams = (state: CalendarState) => {
    viewerBiLogger.util.updateDefaults(mapStateToDefaultBiParams(state));
  };

  viewerBiLogger.util.updateDefaults({
    ...widgetDefaults,
    businessProperties: JSON.stringify({
      language: businessInfo?.language,
      countryCode: businessInfo?.countryCode,
    }),
    ...mapStateToDefaultBiParams(initialState),
    defaultDateAtFirstLoad: settings.get(
      settingsParams.initializeWithFirstAvailableDate,
    )
      ? InitializeCalendarDateOptions.FIRST_AVAILABLE_DATE
      : InitializeCalendarDateOptions.TODAY,
  });

  return Object.assign(viewerBiLogger, {
    update: updateDefaultBiParams,
  }) as CalendarBiLogger;
}
