import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';

export type OnTimeSelectedAction = (selectedTime: string) => void;

export function createOnTimeSelectedAction({
  getControllerState,
  context,
}: ActionFactoryParams<CalendarState, CalendarContext>): OnTimeSelectedAction {
  return async (selectedTime: string) => {
    const [state, setState] = getControllerState();
    const { biLogger } = context;
    const { availableSlots } = state;

    const selectableSlots = availableSlots?.availabilityEntries?.filter(
      (availableSlot) => {
        return availableSlot.slot?.start === selectedTime;
      },
    );

    setState({
      selectedOptions: {},
      selectableSlots: selectableSlots ?? [],
      selectedTime,
    });

    void biLogger.bookingsCalendarClick({
      element: WidgetElements.TIME_SLOT,
      component: WidgetComponents.TIME_PICKER,
      properties: JSON.stringify({
        selectedTime,
      }),
    });
  };
}
