import { QueryDateAvailabilityResponse } from '@wix/ambassador-availability-calendar/types';
import {
  BulkRequest,
  BulkResponse,
  ServicesCatalogServer,
  GetActiveFeaturesResponse,
} from '@wix/ambassador-services-catalog-server/http';
import {
  mapCatalogServiceResponseToService,
  mapResponseToBusinessInfo,
} from '@wix/bookings-uou-mappers';
import { BusinessInfoBase, CatalogData } from '@wix/bookings-uou-types';
import { mockGetDateAvailabilityResponse } from '../../__tests__/mocks/ambassador/mockGetDateAvailabilityResponse';
import { DateAvailabilityFilter } from './calendarApi';

export interface CatalogDataFilter {
  serviceSlug?: string;
}
export interface BookingsApi {
  getCatalogData: ({ serviceSlug }: CatalogDataFilter) => Promise<CatalogData>;
  getDateAvailability: ({
    from,
    to,
    timezone,
  }: DateAvailabilityFilter) => Promise<QueryDateAvailabilityResponse>;
}

export interface BookingsApiInitParams {
  authorization: string;
  baseUrl: string;
}

export const CATALOG_SERVER_URL = '_api/services-catalog';

export function createBookingsApi({
  authorization,
  baseUrl,
}: BookingsApiInitParams): BookingsApi {
  const catalogServer = ServicesCatalogServer(
    `${baseUrl}${CATALOG_SERVER_URL}`,
  );

  return {
    async getCatalogData({
      serviceSlug,
    }: CatalogDataFilter): Promise<CatalogData> {
      const servicesCatalogService = catalogServer.Bulk();

      const bulkRequest: BulkRequest = createBulkRequest({ serviceSlug });
      const catalogData: BulkResponse = await servicesCatalogService({
        Authorization: authorization,
      }).get(bulkRequest);

      const services = catalogData.responseServices!.services!.map((service) =>
        mapCatalogServiceResponseToService(service),
      );
      const businessInfo: BusinessInfoBase = mapResponseToBusinessInfo(
        catalogData.responseBusiness!,
      );
      const activeFeatures: GetActiveFeaturesResponse = catalogData.responseBusiness!
        .activeFeatures!;

      return { services, businessInfo, activeFeatures };
    },
    async getDateAvailability({
      from,
    }: DateAvailabilityFilter): Promise<QueryDateAvailabilityResponse> {
      // TODO: getNextAvailableDate API
      /* Response for Demo */
      const dateAvailability = mockGetDateAvailabilityResponse();
      const promise = new Promise<QueryDateAvailabilityResponse>((resolve) =>
        setTimeout(() => resolve(dateAvailability), 1000),
      );
      return promise;
    },
  };
}

function createBulkRequest({ serviceSlug }: CatalogDataFilter): BulkRequest {
  const bulkRequest: BulkRequest = {
    requestServices: {
      includeDeleted: false,
      query: {
        fieldsets: [],
        filter: serviceSlug ? `{"slugs.name": "${serviceSlug}"}` : null,
        paging: {
          limit: 500,
        },
        fields: [],
        sort: [],
      },
    },
    requestBusiness: {
      suppressNotFoundError: false,
    },
  };
  return bulkRequest;
}
