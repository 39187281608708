export const widgetDefaults = {
  pageName: 'booking_calendar_widget',
};

export enum ErrorMessages {
  NO_SERVICE_AVAILABILITY = 'NO_SERVICE_AVAILABILITY',
}

export enum WidgetComponents {
  TIME_PICKER = 'TIME_PICKER',
}

export enum WidgetElements {
  TIME_SLOT = 'TIME_SLOT',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'GO_TO_NEXT_AVAILABLE_DATE_LINK',
}

export enum TriggeredByOptions {
  INITIALIZE_WIDGET = 'INITIALIZE_WIDGET',
  DATE_SELECTED = 'DATE_SELECTED',
  GO_TO_NEXT_AVAILABLE_DATE_LINK = 'GO_TO_NEXT_AVAILABLE_DATE_LINK',
}

export enum InitializeCalendarDateOptions {
  FIRST_AVAILABLE_DATE = 'FIRST_AVAILABLE_DATE',
  TODAY = 'TODAY',
}

export type TimeSlotsAvailability = {
  AVAILABLE: number;
  FULL: number;
  TOO_LATE_TO_BOOK: number;
  TOO_EARLY_TO_BOOK: number;
};
