import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import settingsParams from '../../settingsParams';

export enum BottomSectionStatus {
  LOADING = 'LOADING',
  NO_SERVICE_AVAILABILITY = 'NO_SERVICE_AVAILABILITY',
  LOADED = 'LOADED',
}

export type WidgetViewModel = {
  status: BottomSectionStatus;
  dateAndTimeSectionHeaderText: string;
  bookingDetailsSectionHeaderText: string;
};

export function createWidgetViewModel({
  state,
  context: { settings },
}: ViewModelFactoryParams<CalendarState, CalendarContext>): WidgetViewModel {
  return {
    status: state.bottomSectionStatus,
    dateAndTimeSectionHeaderText: settings.get(
      settingsParams.dateAndTimeSectionHeaderText,
    ),
    bookingDetailsSectionHeaderText: settings.get(
      settingsParams.bookingDetailsSectionHeaderText,
    ),
  };
}
