import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { formatTimeToViewFormat } from '../../../../utils/dateAndTime/dateAndTime';
import {
  getTimeSlotsAvailabilityStatuses,
  TimeSlotAvailabilityStatus,
} from '../../../../utils/timeSlots/timeSlots';

export interface TimeSlotStatus extends TimeSlotAvailabilityStatus {
  selected: boolean;
}

export interface TimeSlot {
  formattedStartTime: string;
  startTimestamp: string;
  status: TimeSlotStatus;
}

export type TimeSelectionViewModel = {
  timeSlots: TimeSlot[];
};

export function createTimeSelectionViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): TimeSelectionViewModel {
  const { availableSlots } = state;

  const timeSlotsAvailabilityStatuses: Map<
    string,
    TimeSlotAvailabilityStatus
  > = getTimeSlotsAvailabilityStatuses(availableSlots!);

  const timeSlots = createTimeSlots(
    timeSlotsAvailabilityStatuses,
    state,
    context,
  );

  return {
    timeSlots,
  };
}

const createTimeSlots = (
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>,
  state: CalendarState,
  context: CalendarContext,
) => {
  const { selectedTime } = state;
  const { businessInfo } = context;
  const locale = businessInfo.dateRegionalSettingsLocale;

  const timeSlots: TimeSlot[] = [];
  timeSlotsAvailabilityStatuses.forEach(
    (timeSlotStatus: TimeSlotAvailabilityStatus, startTimestamp: string) => {
      const formattedStartTime = startTimestamp
        ? formatTimeToViewFormat(startTimestamp, locale)
        : '';
      const isTimeSelected = startTimestamp === selectedTime;

      timeSlots.push({
        startTimestamp,
        formattedStartTime,
        status: { ...timeSlotStatus, selected: isTimeSelected },
      });
    },
  );

  return timeSlots;
};
