
  import userController from '/home/builduser/agent00/work/d25968c86d626f19/statics/viewer/bookings-calendar-widget/src/components/BookingCalendar/controller.ts';
  import createControllerWrapper from 'yoshi-flow-editor-runtime/build/esm/controllerWrapper.js';
  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';
  

  import { initI18n as initI18n } from 'yoshi-flow-editor-runtime/build/esm/i18next/init';



  var sentryConfig = {
    DSN: 'https://5c05b49c51a145fb801c55a588742af8@sentry.wixpress.com/1120',
    id: '5c05b49c51a145fb801c55a588742af8',
    projectName: 'bookings-calendar-widget',
    teamName: 'bookings',
  };

  var experimentsConfig = {"scopes":["test"]};

  var translationsConfig = {"defaultTranslationsPath":"/home/builduser/agent00/work/d25968c86d626f19/statics/viewer/bookings-calendar-widget/src/assets/locales/messages_en.json","icuEnabled":true};

  var biConfig = {"owner":"@wix/bi-logger-wixboost-users","visitor":"@wix/bi-logger-wixboost-ugc","enableUniversalEvents":true};

  var defaultTranslations = {"app.widget.welcome":"Welcome","duration.units.hours":"{hours} hr","duration.units.aria-hours":"{hours} hours","duration.units.minutes":"{minutes} min","duration.units.aria-minutes":"{minutes} minutes","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","app.settings.defaults.header-subtitle":"Check out our availability and book the date and time that works for you","app.settings.defaults.empty-state.no-availability.title":"This service isn’t available for booking.","app.settings.defaults.empty-state.no-availability.subtitle":"Please contact us or check out our other services.","app.settings.defaults.widget.date-and-time-header":"Select a Date and Time","app.settings.defaults.widget.booking-details-header":"Booking Details","app.settings.defaults.time-picker.no-slots-message":"No available time slots","app.settings.defaults.time-picker.go-to-next-available-day":"Go to next available date","app.settings.defaults.video-conference-badge-text":"Available Online","app.settings.defaults.booking-details.preferences.title":"Preferences","app.settings.defaults.booking-details.staff-member-selection":"Staff Member","app.settings.defaults.booking-details.locations-selection":"Location","app.settings.defaults.booking-details.durations-selection":"Duration","app.settings.defaults.booking-details.payment.title":"Payment","app.settings.defaults.booking-details.pricing-plan.title":"Pricing plan","app.settings.defaults.booking-details.clear.title":"Clear"};

  import biLogger from '/home/builduser/agent00/work/d25968c86d626f19/statics/viewer/bookings-calendar-widget/node_modules/@wix/bi-logger-wixboost-ugc/dist/src/index.js';

  const _controller = createControllerWrapper(userController, {
    sentryConfig,
    biConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    appName: "Wix Bookings",
    appDefinitionId: "13d21c63-b5ec-5912-8397-c3a5ddb27a97",
    componentId: "54d912c5-52cb-4657-b8fa-e1a4cda8ed01",
    projectName: "bookings-calendar-widget",
    defaultTranslations,
    localeDistPath: "assets/locales"
  }, {
    initI18n,
     
  });
  export const controller = _controller;
  export default _controller;
