import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { OnDateSelectedAction } from '../onDateSelectedAction/onDateSelectedAction';
import { TimePickerStatus } from '../../ViewModel/timePickerViewModel/timePickerViewModel';
import {
  TriggeredByOptions,
  WidgetComponents,
  WidgetElements,
} from '../../../../utils/bi/consts';

export type GoToNextAvailableDateAction = () => void;

export function createGoToNextAvailableDateAction(
  {
    getControllerState,
    context: { biLogger, calendarApi },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  onDateSelectedAction: OnDateSelectedAction,
): GoToNextAvailableDateAction {
  return async () => {
    const [state, setState] = getControllerState();
    const { selectedDate } = state;

    setState({
      timePickerStatus: TimePickerStatus.LOADING,
    });

    const nextAvailableDate = await calendarApi.getNextAvailableDate({
      from: selectedDate!,
    });

    if (nextAvailableDate) {
      await onDateSelectedAction(
        nextAvailableDate,
        TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
      );

      void biLogger.bookingsCalendarClick({
        component: WidgetComponents.TIME_PICKER,
        element: WidgetElements.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        properties: JSON.stringify({
          selectedDate,
          nextAvailableDate,
        }),
      });
    }
  };
}
