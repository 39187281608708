import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';
import {
  PaymentDtoMapper,
  isServiceOfferedAsPricingPlan,
  DurationMapper,
  DurationMapperOptions,
} from '@wix/bookings-uou-mappers';
import {
  formatRFCtoDateAndTime,
  minutesDifferencesBetweenTwoDates,
} from '../../../../utils/dateAndTime/dateAndTime';
import settingsParams from '../../settingsParams';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';

export type SelectableOption = {
  id?: string;
  value?: string;
  isSelectable?: boolean;
};
export type SelectedOptions = {
  location?: string;
  staffMember?: string;
  duration?: string;
};

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  selectableLocations: SelectableOption[];
  selectableStaffMembers: SelectableOption[];
  selectableDurations: SelectableOption[];
  bookingDetailsPreferencesHeaderText: string;
  bookingDetailsStaffMemberDropDownText: string;
  bookingDetailsLocationDropDownText: string;
  bookingDetailsDurationDropDownText: string;
  bookingDetailsClearText: string;
  videoConferenceBadgeText?: string;
};

export async function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Promise<BookingDetailsViewModel> {
  const { businessInfo, t, settings, wixSdkAdapter } = context;
  const {
    selectedService,
    selectableSlots,
    selectedTime,
    selectedOptions,
  } = state;

  const [
    bookingDetailsPreferencesHeaderText,
    bookingDetailsStaffMemberDropDownText,
    bookingDetailsLocationDropDownText,
    bookingDetailsDurationDropDownText,
    bookingDetailsPricingPlanText,
    bookingDetailsClearText,
  ] = getSettingsTexts(settings);

  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
  const serviceName = selectedService.info.name;

  const paymentDescription = await getPaymentDescription(
    wixSdkAdapter,
    selectedService.payment,
    dateRegionalSettingsLocale,
    bookingDetailsPricingPlanText,
  );

  const defaultViewModel = {
    serviceName,
    paymentDescription,
    bookingDetailsPreferencesHeaderText,
    bookingDetailsStaffMemberDropDownText,
    bookingDetailsLocationDropDownText,
    bookingDetailsDurationDropDownText,
    bookingDetailsClearText,
  };

  let dateAndTime;
  let selectableLocations: SelectableOption[] = [];
  let selectableStaffMembers: SelectableOption[] = [];
  let selectableDurations: SelectableOption[] = [];
  if (selectableSlots && selectedTime) {
    dateAndTime = formatRFCtoDateAndTime(
      selectedTime,
      dateRegionalSettingsLocale,
    );
    selectableLocations = getSelectableLocations(
      selectableSlots,
      t,
      dateRegionalSettingsLocale,
      selectedOptions,
    );

    selectableStaffMembers = getSelectableStaffMembers(
      selectableSlots,
      t,
      dateRegionalSettingsLocale,
      selectedOptions,
    );
    selectableDurations = getSelectableDurations(
      selectableSlots,
      dateRegionalSettingsLocale,
      t,
      selectedOptions,
    );
  }
  const videoConferenceBadgeText = selectedService.videoConferenceProviderId
    ? settings.get(settingsParams.videoConferenceBadgeText)
    : '';

  return {
    ...defaultViewModel,
    dateAndTime,
    selectableDurations,
    selectableLocations,
    selectableStaffMembers,
    videoConferenceBadgeText,
  };
}

const getPaymentDescription = async (
  wixSdkAdapter: WixOOISDKAdapter,
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
  bookingDetailsPricingPlanText: string,
) => {
  const paymentDescription = [];

  const isServiceBookableWithPricingPlan = await wixSdkAdapter.isPricingPlanInstalled();
  if (isOfferedAsOneTime(payment)) {
    const priceText = getPriceText(payment, dateRegionalSettingsLocale);
    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return paymentDescription.join(' | ');
};

const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};

const isLocationOptionSelectable = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  return (
    filterSlotByStaffMemberSelectedOption(slot, selectedOptions) &&
    filterSlotByDurationSelectedOption(
      t,
      dateRegionalSettingsLocale,
      slot,
      selectedOptions,
    )
  );
};
const getSelectableLocations = (
  selectableSlots: SlotAvailability[],
  t: TFunction,
  dateRegionalSettingsLocale: string,
  selectedOptions?: SelectedOptions,
): SelectableOption[] => {
  const filteredSelectableLocation: SlotAvailability[] = [];
  selectableSlots.forEach((selectableSlot) => {
    const locationId = selectableSlot.slot?.location?.id!;
    const isLocationExist = filteredSelectableLocation.some(
      (selectableLocation) =>
        selectableLocation.slot?.location?.id === locationId,
    );
    !isLocationExist && filteredSelectableLocation.push(selectableSlot);
  });

  const selectableLocations = filteredSelectableLocation.map(
    (selectableSlot: SlotAvailability) => {
      const locationName = selectableSlot.slot?.location?.name!;
      const locationId = selectableSlot.slot?.location?.id;
      return {
        id: locationId,
        value: locationName,
        isSelectable: isLocationOptionSelectable(
          t,
          dateRegionalSettingsLocale,
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );
  return selectableLocations;
};

const isStaffMemberOptionSelectable = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  return (
    filterSlotByLocationSelectedOption(slot, selectedOptions) &&
    filterSlotByDurationSelectedOption(
      t,
      dateRegionalSettingsLocale,
      slot,
      selectedOptions,
    )
  );
};
const getSelectableStaffMembers = (
  selectableSlots: SlotAvailability[],
  t: TFunction,
  dateRegionalSettingsLocale: string,
  selectedOptions?: SelectedOptions,
): SelectableOption[] => {
  const selectableStaffMembersFiltered: SlotAvailability[] = [];
  selectableSlots.forEach((selectableSlot) => {
    const staffMemberId = selectableSlot.slot?.resource?.id;
    const isStaffMemberExist = selectableStaffMembersFiltered.some(
      (member) => member.slot?.resource?.id === staffMemberId,
    );
    !isStaffMemberExist && selectableStaffMembersFiltered.push(selectableSlot);
  });

  const selectableStaffMembers = selectableStaffMembersFiltered.map(
    (selectableSlot: SlotAvailability) => {
      const staffMemberName = selectableSlot.slot?.resource?.name!;
      const staffMemberId = selectableSlot.slot?.resource?.id;

      return {
        id: staffMemberId,
        value: staffMemberName,
        isSelectable: isStaffMemberOptionSelectable(
          t,
          dateRegionalSettingsLocale,
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  return selectableStaffMembers;
};

export const filterSlotByLocationSelectedOption = (
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  const locationId = slot?.location?.id;
  const locationSelectedOption = selectedOptions?.location;

  if (locationSelectedOption && locationId !== locationSelectedOption) {
    return false;
  }
  return true;
};

export const filterSlotByStaffMemberSelectedOption = (
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  const staffMemberId = slot?.resource?.id;
  const staffMemberSelectedOption = selectedOptions?.staffMember;

  if (
    staffMemberSelectedOption &&
    staffMemberId !== staffMemberSelectedOption
  ) {
    return false;
  }
  return true;
};

export const filterSlotByDurationSelectedOption = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  const startDate = slot?.start!;
  const endDate = slot?.end!;
  const duration = getSlotDuration(
    startDate,
    endDate,
    t,
    dateRegionalSettingsLocale,
  );

  const durationSelectedOption = selectedOptions?.duration;
  if (durationSelectedOption && duration !== durationSelectedOption) {
    return false;
  }
  return true;
};

const isDurationOptionSelectable = (
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  return (
    filterSlotByStaffMemberSelectedOption(slot, selectedOptions) &&
    filterSlotByLocationSelectedOption(slot, selectedOptions)
  );
};
export const getSelectableDurations = (
  selectableSlots: SlotAvailability[],
  dateRegionalSettingsLocale: string,
  t: TFunction,
  selectedOptions?: SelectedOptions,
): SelectableOption[] => {
  const selectableDurations = selectableSlots.map(
    (selectableSlot: SlotAvailability) => {
      const startTime = selectableSlot.slot?.start!;
      const endTime = selectableSlot.slot?.end!;
      const slotDuration = getSlotDuration(
        startTime,
        endTime,
        t,
        dateRegionalSettingsLocale,
      );

      return {
        id: slotDuration,
        value: slotDuration,
        isSelectable: isDurationOptionSelectable(
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  const selectableDurationsFiltered: SelectableOption[] = [];
  selectableDurations.forEach((duration) => {
    const isDurationExist = selectableDurationsFiltered.some(
      (selectableDuration) => selectableDuration.value === duration.value,
    );
    !isDurationExist && selectableDurationsFiltered.push(duration);
  });
  return selectableDurationsFiltered;
};

export const getSlotDuration = (
  startTime: string,
  endTime: string,
  t: TFunction,
  dateRegionalSettingsLocale: string,
): string => {
  const durationInMinutes = minutesDifferencesBetweenTwoDates(
    startTime,
    endTime,
  );

  const durationOptions: DurationMapperOptions = {
    hourUnit: 'duration.units.hours',
    hourAriaUnit: 'duration.units.aria-hours',
    minuteUnit: 'duration.units.minutes',
    minuteAriaUnit: 'duration.units.aria-minutes',
  };

  const durationMapper = new DurationMapper(
    dateRegionalSettingsLocale,
    durationOptions,
    t,
  );

  return durationMapper.durationTextFromMinutes(durationInMinutes);
};

const getSettingsTexts = (settings: { get: any }) => {
  const bookingDetailsPreferencesHeaderText = settings.get(
    settingsParams.bookingDetailsPreferencesHeaderText,
  );
  const bookingDetailsStaffMemberDropDownText = settings.get(
    settingsParams.bookingDetailsStaffMemberDropDownText,
  );
  const bookingDetailsLocationDropDownText = settings.get(
    settingsParams.bookingDetailsLocationDropDownText,
  );
  const bookingDetailsDurationDropDownText = settings.get(
    settingsParams.bookingDetailsDurationDropDownText,
  );
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );
  const bookingDetailsClearText = settings.get(
    settingsParams.bookingDetailsClearText,
  );
  return [
    bookingDetailsPreferencesHeaderText,
    bookingDetailsStaffMemberDropDownText,
    bookingDetailsLocationDropDownText,
    bookingDetailsDurationDropDownText,
    bookingDetailsPricingPlanText,
    bookingDetailsClearText,
  ];
};
