import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { BottomSectionStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { OnDateSelectedAction } from '../onDateSelectedAction/onDateSelectedAction';
import { OnMonthSelectedAction } from '../onMonthSelectedAction/onMonthSelectedAction';
import { getFormattedDateString } from '../../../../utils/dateAndTime/dateAndTime';
import { TriggeredByOptions } from '../../../../utils/bi/consts';

export type InitializeWidgetAction = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context: { wixSdkAdapter, biLogger },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  onDateSelectedAction: OnDateSelectedAction,
  onMonthSelectedAction: OnMonthSelectedAction,
): InitializeWidgetAction {
  return async () => {
    if (!wixSdkAdapter.isSSR()) {
      const [, setState] = getControllerState();

      const dateString = getFormattedDateString(new Date());

      const triggeredByOption = TriggeredByOptions.INITIALIZE_WIDGET;
      await Promise.all([
        onDateSelectedAction(dateString, triggeredByOption),
        onMonthSelectedAction(dateString, triggeredByOption),
      ]);
      setState({ bottomSectionStatus: BottomSectionStatus.LOADED });

      await biLogger.bookingsCalendarPageLoaded({});

      // TODO handle empty state
      // setState({ bottomSectionStatus: BottomSectionStatus.NO_SERVICE_AVAILABILITY });
      // void biLogger.bookingsCalendarPageLoaded({
      //    errorMessage: ErrorMessages.noServiceAvailability,
      //  });
    }
  };
}
