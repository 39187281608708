import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import settingsParams from '../../settingsParams';
import { CalendarContext } from '../../../../utils/context/contextFactory';

export type NoAvailabilityViewModel = {
  title: string;
  subtitle: string;
};

export function createNoAvailabilityViewModel({
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): NoAvailabilityViewModel {
  const { settings } = context;
  return {
    title: settings.get(settingsParams.noAvailabilityTitle),
    subtitle: settings.get(settingsParams.noAvailabilitySubtitle),
  };
}
