import {
  AvailabilityLocation,
  QueryAvailabilityResponse,
  SlotAvailability,
  SlotResource,
} from '@wix/ambassador-availability-calendar/types';
import { getFormattedDateString } from '../../../src/utils/dateAndTime/dateAndTime';

export function mockGetSlotsForSelectedDate(
  date: string,
): Promise<QueryAvailabilityResponse> {
  let availabilityEntries: SlotAvailability[] = [];
  const day = date.substr(0, date.indexOf('T'));

  if (date === mockDateAvailability.dateWithSlots1) {
    const startTimes = ['08:00', '08:00', '08:30', '09:00', '09:30', '10:00'];
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            date: day,
            startTime,
            endTime: ['12:00', '14:00'][index % 2],
            location: {
              formattedAddress: ['Tel Aviv', 'Los Angeles'][index % 2],
              id: `location-${index}`,
              name: ['Tel Aviv', 'Los Angeles'][index % 2],
            },
            resource: {
              id: `staffMember-${index}`,
              name: `Roni-${index}`,
            },
          }),
          spotsOpen: 5,
          spotsTotal: 10,
        };
      },
    );
    const fullSlotAvailability: SlotAvailability = {
      isBookable: true,
      slot: createSlot({
        id: 'full',
        date: day,
        startTime: '17:00',
        endTime: '18:49',
        location: {
          formattedAddress: 'Los Angeles',
          id: '456',
          name: 'Los Angeles',
        },
        resource: {
          id: '123',
          name: 'Roni',
        },
      }),
      spotsOpen: 10,
      spotsTotal: 10,
    };
    availabilityEntries = [...bookableSlotsAvailability, fullSlotAvailability];
  } else if (date === mockDateAvailability.dateWithSlots2) {
    const tooLateToBookSlotAvailability: SlotAvailability = {
      isBookable: true,
      slot: createSlot({
        id: 'tooLateToBook',
        date: day,
        startTime: '14:00',
        endTime: '15:30',
        location: {
          formattedAddress: 'Los Angeles',
          id: '1',
          name: 'Los Angeles',
        },
        resource: {
          id: '1',
          name: 'Roni',
        },
      }),
      spotsOpen: 4,
      spotsTotal: 10,
      bookingPolicyViolations: {
        isTooLateToBook: true,
      },
    };
    availabilityEntries = [tooLateToBookSlotAvailability];
  } else if (
    date === mockDateAvailability.dateWithSlots3 ||
    date === mockDateAvailability.dateWithSlots4
  ) {
    const startTimes = ['09:00', '11:30'];
    const bookableSlotsAvailability = startTimes.map(
      (startTime, index): SlotAvailability => {
        return {
          isBookable: true,
          slot: createSlot({
            id: `${index}`,
            date: day,
            startTime,
            endTime: '18:49',
            location: {
              formattedAddress: 'Los Angeles',
              id: '1',
              name: 'Los Angeles',
            },
            resource: {
              id: '2',
              name: 'David',
            },
          }),
          spotsOpen: 5,
          spotsTotal: 10,
        };
      },
    );
    const tooEarlyToBookSlotAvailability: SlotAvailability = {
      isBookable: true,
      slot: createSlot({
        id: 'tooEarlyToBookSlot',
        date: day,
        startTime: '18:00',
        endTime: '18:49',
        location: {
          formattedAddress: 'Los Angeles',
          id: '1',
          name: 'Los Angeles',
        },
        resource: {
          id: '1',
          name: 'Roni',
        },
      }),
      spotsOpen: 4,
      spotsTotal: 10,
      bookingPolicyViolations: {
        isTooEarlyToBook: true,
      },
    };
    availabilityEntries = [
      ...bookableSlotsAvailability,
      tooEarlyToBookSlotAvailability,
    ];
  } else {
  }

  const queryAvailabilityResponse: QueryAvailabilityResponse = {
    availabilityEntries,
  };

  const promise = new Promise<QueryAvailabilityResponse>((resolve) => {
    setTimeout(() => resolve(queryAvailabilityResponse), 500);
  });

  return promise;
}

export const mockDateAvailability = {
  noSlotsDate: getFormattedDateString(new Date()),
  dateWithSlots1: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 2),
  ),
  dateWithSlots2: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 4),
  ),
  dateWithSlots3: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 7),
  ),
  dateWithSlots4: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 10),
  ),
  dateWithNoSlots1: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 3),
  ),
  dateWithNoSlots2: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 6),
  ),
  dateWithNoSlots3: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 9),
  ),
  dateWithNoSlots4: getFormattedDateString(
    new Date(+new Date() + 1000 * 60 * 60 * 24 * 12),
  ),
};

const createSlot = ({
  id,
  date,
  startTime,
  endTime,
  location,
  resource,
}: {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  location: AvailabilityLocation;
  resource: SlotResource;
}) => {
  return {
    id,
    start: `${date}T${startTime}:53Z`,
    end: `${date}T${endTime}:53Z`,
    location,
    resource,
  };
};
