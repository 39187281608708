import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import settingsParams from '../../settingsParams';

export type NoAvailableSlotsViewModel = {
  noSlotsText: string;
  goToNextAvailableLinkText: string;
};

export function createNoAvailableSlotsViewModel({
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): NoAvailableSlotsViewModel {
  const { settings } = context;
  const noSlotsText = settings.get(settingsParams.timePickerNoSlotsText);
  const goToNextAvailableLinkText = settings.get(
    settingsParams.timePickerGoToNextAvailableLinkText,
  );

  return {
    noSlotsText,
    goToNextAvailableLinkText,
  };
}
