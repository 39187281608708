import {
  CatalogData,
  Service,
  ServiceType,
  OfferedAsType,
  PaymentType,
  TimezoneType,
} from '@wix/bookings-uou-types';

export function createDummyCatalogData(): CatalogData {
  const service: Service = {
    id: 'some-id',
    info: {
      name: 'Some Service',
      tagline: 'Some Tag Line',
      type: ServiceType.INDIVIDUAL,
    },
    locations: [],
    staffMembers: [
      {
        id: 'staff-1-id',
        name: 'Staff 1',
      },
    ],
    policy: {
      uouHidden: false,
      isBookOnlineAllowed: true,
      maxParticipantsPerBook: 1,
      capacity: 1,
    },
    payment: {
      offeredAs: [OfferedAsType.ONE_TIME],
      paymentDetails: {
        currency: '$',
        price: 1,
        priceText: '',
        minCharge: 0,
        isFree: false,
        paymentType: PaymentType.ONLINE,
        displayTextForPlan: '',
      },
    },
  };
  return {
    services: [service],
    businessInfo: {
      language: 'en',
      currency: '$',
      timeZone: 'America/New_York',
      timezoneProperties: {
        defaultTimezone: TimezoneType.BUSINESS,
        clientCanChooseTimezone: true,
      },
      regionalSettingsLocale: 'en-US',
    },
    activeFeatures: {
      applicableForCourse: true,
      bookingsStaffLimit: 1,
      applicableForServiceList: true,
      applicableForGroups: true,
      applicableForIndividual: true,
      applicableForPayments: true,
      applicableForReminders: true,
      applicableForSmsReminders: true,
    },
  };
}
