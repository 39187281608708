import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SelectedOptions } from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';

export type OnOptionSelectedAction = (
  selectedOptions: SelectedOptions,
) => Promise<void>;

export function createOnOptionSelectedAction({
  getControllerState,
  context,
}: ActionFactoryParams<
  CalendarState,
  CalendarContext
>): OnOptionSelectedAction {
  return async (selectedOptions) => {
    const [state, setState] = getControllerState();
    const { selectedOptions: prevSelectedOptions } = state;

    setState({
      selectedOptions: {
        ...prevSelectedOptions,
        ...selectedOptions,
      },
    });
  };
}
