import { CatalogData } from '@wix/bookings-uou-types';
import { BookingsApi, createBookingsApi } from './bookingsApi';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyCatalogData } from './dummyData/dummyCatalogData';
import {
  QueryAvailabilityResponse,
  QueryDateAvailabilityResponse,
} from '@wix/ambassador-availability-calendar/types';
import { mockGetSlotsForSelectedDate } from '../../__tests__/mocks/ambassador/mockGetSlotsForSelectedDate';
import { getFormattedDateString } from '../utils/dateAndTime/dateAndTime';

const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export interface CalendarApiInitParams {
  wixSdkAdapter: WixOOISDKAdapter;
}

export interface DateAvailabilityFilter {
  from: string;
  to?: string;
  timezone?: string;
}
export interface CalendarApi {
  getCatalogData: () => Promise<CatalogData>;
  getNextAvailableDate: ({
    from,
  }: DateAvailabilityFilter) => Promise<string | undefined>;
  getDateAvailability: ({
    from,
  }: DateAvailabilityFilter) => Promise<QueryDateAvailabilityResponse>;
  getSlotsForSelectedDate: (date: string) => Promise<QueryAvailabilityResponse>;
}

export function createCalendarApi({
  wixSdkAdapter,
}: CalendarApiInitParams): CalendarApi {
  const bookingsApi: BookingsApi = createBookingsApi({
    authorization: wixSdkAdapter.getInstance(),
    baseUrl: wixSdkAdapter.getServerBaseUrl(),
  });

  return {
    async getCatalogData(): Promise<CatalogData> {
      if (wixSdkAdapter.isEditorMode()) {
        return createDummyCatalogData();
      }

      const serviceSlug = await wixSdkAdapter.getServiceSlug(
        CALENDAR_PAGE_URL_PATH_PARAM,
      );
      const catalogData = await bookingsApi.getCatalogData({ serviceSlug });

      return catalogData;
    },
    async getNextAvailableDate({ from }): Promise<string | undefined> {
      const dateAvailabilityResponse = await bookingsApi.getDateAvailability({
        from,
      });
      const nextAvailableDate = dateAvailabilityResponse.nextAvailable?.date;
      const formattedNextAvailableDate =
        nextAvailableDate && getFormattedDateString(nextAvailableDate);

      return formattedNextAvailableDate;
    },
    async getDateAvailability({
      from,
    }): Promise<QueryDateAvailabilityResponse> {
      const dateAvailabilityResponse = await bookingsApi.getDateAvailability({
        from,
      });
      return dateAvailabilityResponse;
    },
    async getSlotsForSelectedDate(
      date: string,
    ): Promise<QueryAvailabilityResponse> {
      return mockGetSlotsForSelectedDate(date);
    },
  };
}
