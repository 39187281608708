import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../controller';
import { CalendarContext } from '../../../utils/context/contextFactory';
import {
  createInitializeWidgetAction,
  InitializeWidgetAction,
} from './initializeWidgetAction/initializeWidgetAction';
import {
  createOnDateSelectedAction,
  OnDateSelectedAction,
} from './onDateSelectedAction/onDateSelectedAction';
import {
  createGoToNextAvailableDateAction,
  GoToNextAvailableDateAction,
} from './goToNextAvailableDateAction/goToNextAvailableDateAction';
import {
  createOnMonthSelectedAction,
  OnMonthSelectedAction,
} from './onMonthSelectedAction/onMonthSelectedAction';
import {
  createOnTimeSelectedAction,
  OnTimeSelectedAction,
} from './onTimeSelectedAction/onTimeSelectedAction';
import {
  createOnOptionSelectedAction,
  OnOptionSelectedAction,
} from './onOptionSelectedAction/onOptionSelectedAction';

export type CalendarActions = {
  initializeWidgetAction: InitializeWidgetAction;
  onDateSelectedAction: OnDateSelectedAction;
  goToNextAvailableDateAction: GoToNextAvailableDateAction;
  onMonthSelectedAction: OnMonthSelectedAction;
  onTimeSelectedAction: OnTimeSelectedAction;
  onOptionSelectedAction: OnOptionSelectedAction;
};

export function createCalendarActions(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): CalendarActions {
  const onDateSelectedAction = createOnDateSelectedAction(actionFactoryParams);
  const onMonthSelectedAction = createOnMonthSelectedAction(
    actionFactoryParams,
  );
  return {
    initializeWidgetAction: createInitializeWidgetAction(
      actionFactoryParams,
      onDateSelectedAction,
      onMonthSelectedAction,
    ),
    onDateSelectedAction,
    goToNextAvailableDateAction: createGoToNextAvailableDateAction(
      actionFactoryParams,
      onDateSelectedAction,
    ),
    onMonthSelectedAction,
    onTimeSelectedAction: createOnTimeSelectedAction(actionFactoryParams),
    onOptionSelectedAction: createOnOptionSelectedAction(actionFactoryParams),
  };
}
