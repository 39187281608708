import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';

export type OnMonthSelectedAction = (
  date: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createOnMonthSelectedAction({
  getControllerState,
  context: { calendarApi, biLogger },
}: ActionFactoryParams<CalendarState, CalendarContext>): OnMonthSelectedAction {
  return async (monthDateString: string, triggeredBy: TriggeredByOptions) => {
    const [, setState] = getControllerState();
    setState({
      selectedMonth: monthDateString,
    });

    const dateAvailability = await calendarApi.getDateAvailability({
      from: monthDateString,
    });

    setState({
      dateAvailability,
    });

    biLogger.bookingsCalendarDatePickerLoad({
      dateAvailability: JSON.stringify({
        datesWithAvailableSlots:
          dateAvailability?.availabilityEntries?.filter(
            (availabilityEntry) => availabilityEntry.hasBookableSlots,
          ).length || 0,
        datesWithUnavailableSlots:
          dateAvailability?.availabilityEntries?.filter(
            (availabilityEntry) => !availabilityEntry.hasBookableSlots,
          ).length || 0,
      }),
      triggeredBy,
    });
  };
}
